<template>
	<v-row>
		<v-dialog v-model="dialog.status" max-width="575" persistent>
			<CardDialog :dialog="dialog" />
		</v-dialog>
		<v-dialog v-model="dialogReabertura" max-width="575" persistent>
			<v-card min-width="350">
				<v-card-title class="font-weight-bold text-h5">Motivo da reabertura</v-card-title>
				<v-divider />
				<v-card-text>
					<p
						class="mt-5 text-caption font-weight-bold"
					>Digite no campo abaixo o motivo para reabertura do chamado.</p>
					<v-textarea class="mt-5 mb-n6" counter outlined rows="3" v-model="registro"></v-textarea>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="primary" elevation="0" @click="dialogReabertura = false">Cancelar</v-btn>
					<v-btn color="primary" elevation="0" @click="reabrirChamado()">Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogArquivoNovo" persistent max-width="575">
			<v-card min-width="320">
				<v-card-title>Novo arquivo</v-card-title>
				<v-divider />
				<v-card-text class="pt-5">
					<v-file-input
						counter
						multiple
						show-size
						outlined
						dense
						prepend-icon
						prepend-inner-icon="mdi-paperclip"
						@change="adicionarArquivo"
					></v-file-input>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="error" elevation="0" @click="dialogArquivoNovo = false">Cancelar</v-btn>
					<v-btn color="primary" elevation="0" @click="arquivoNovo()">Enviar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogAtualizar" persistent max-width="575">
			<v-card>
				<v-card-title>Atualizar Chamado</v-card-title>
				<v-divider />
				<v-card-text class="pt-5">
					<v-row>
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Departamento</div>
							<v-select
								class="mb-n10"
								:items="departamentos.lista"
								item-text="departamento"
								item-value="iddepartamento"
								:loading="carregando"
								outlined
								dense
								v-model="dadosNovo.iddepartamento"
								@change="listarCategorias()"
							></v-select>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Categoria</div>
							<v-select
								class="mb-n10"
								:items="categorias.lista"
								item-text="categoria"
								item-value="idcategoria"
								:loading="carregando"
								outlined
								dense
								v-model="dadosNovo.idcategoria"
								@change="listarSubCategorias()"
							></v-select>
						</v-col>
						<v-col cols="12" v-if="subCategorias.lista.length > 0">
							<div class="text-body-1 font-weight-bold">Subcategoria</div>
							<v-select
								class="mb-n5"
								:items="subCategorias.lista"
								item-text="subcategoria"
								item-value="idsubcategoria"
								:loading="carregando"
								outlined
								dense
								v-model="dadosNovo.idsubcategoria"
							></v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="error" elevation="0" @click="dialogAtualizar = false">Cancelar</v-btn>
					<v-btn color="primary" elevation="0" @click="atualizarCompleto()">Atualizar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar color="green darken-3" v-model="snackbar.status" :timeout="snackbar.timeout" dark>
			<span class="text-caption font-weight-bold text-uppercase">{{snackbar.text}}</span>
			<template v-slot:action="{ attrs }">
				<v-btn class="font-weight-bold" text v-bind="attrs" @click="snackbar.status = false">Fechar</v-btn>
			</template>
		</v-snackbar>
		<v-col cols="12" lg="7" order="2" order-lg="1">
			<v-card>
				<v-card-title>Registros</v-card-title>
				<v-divider />
				<v-card elevation="0" max-height="300">
					<v-card-text class="px-1 container-scroll">
						<v-skeleton-loader
							v-if="carregando"
							class="px-2"
							width="100%"
							type="heading, list-item-two-line"
						></v-skeleton-loader>
						<template v-else>
							<v-list two-line class="mt-n3">
								<v-list-item v-for="(u, i) in registros.lista" :key="i" class="mb-n6">
									<v-list-item-content>
										<v-list-item-title>
											<span class="font-weight-bold">{{u.usuarioregistro}}:</span>
											<small class="float-right">{{u.dataregistro}}</small>
										</v-list-item-title>
										<p class="pl-3">
											<v-icon size="14">mdi-forum</v-icon>
											{{u.registro}}
										</p>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</template>
					</v-card-text>
				</v-card>
				<v-card-text>
					<v-textarea v-model="registro" outlined rows="1" :readonly="dados.status == 'Concluído'"></v-textarea>
				</v-card-text>
				<v-card-actions class="pa-4 mt-n12">
					<v-btn
						class="d-none d-md-flex mr-1 mr-md-3"
						text
						color="primary"
						elevation="0"
						href="../../AnyDesk-5-3-3.exe"
					>
						<v-icon class="mr-1" size="16">mdi-download</v-icon>AnyDesk
					</v-btn>
					<v-btn class="d-none d-md-flex" text color="primary" elevation="0" href="../../AA_v3.exe">
						<v-icon class="mr-1" size="16">mdi-download</v-icon>Ammyy Admin
					</v-btn>

					<v-spacer />
					<v-btn
						v-if="usuario.iddepartamento == dados.iddepartamento || usuario.idusuario == dados.idusuariocadastro"
						class="px-3"
						color="primary"
						elevation="0"
						:disabled="dados.status == 'Concluído'"
						@click="adicionarRegistro()"
					>
						<v-icon class="mr-0 mr-md-3">mdi-plus</v-icon>
						<span>Registro</span>
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-card class="mt-6 mb-16">
				<v-card-title>
					Arquivos
					<v-spacer />
					<v-btn @click="dialogArquivoNovo = true" elevation="0" color="primary">
						<v-icon class="pr-3">mdi-plus</v-icon>Arquivo
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="text-tuncrate">
					<v-chip
						@click="arquivoDownload(a.idarquivo)"
						class="ma-2"
						v-for="(a,i) in dados.arquivo"
						:key="i"
					>
						<v-icon left small>mdi-cloud-download</v-icon>
						{{a.arquivoname}}
					</v-chip>
					<span v-if="!dados.arquivo">Não há arquivos anexados no chamado.</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" lg="5" order="1" order-lg="2">
			<v-card>
				<small class="float-right pa-3">
					Nº chamado:
					<strong>{{dados.idchamado}}</strong>
				</small>
				<v-card-title>{{dados.chamado}}</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row align="end" justify="end">
						<v-col>
							<div class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-check-all</v-icon>Status
							</div>
							<div class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-account-multiple-outline</v-icon>Departamento
							</div>
							<div class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-alpha-c-circle-outline</v-icon>Categoria
							</div>
							<div v-if="dados.subcategoria" class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-alpha-s-circle-outline</v-icon>Subcategoria
							</div>
							<div class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-account-outline</v-icon>Solicitante
							</div>
							<div v-if="dados.idconexaoremota" class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-lan-pending</v-icon>AnyDesk
							</div>
							<div class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-office-building</v-icon>Filial
							</div>
							<div class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-clipboard-text-clock-outline</v-icon>Data abertura
							</div>
							<div v-if="dados.dataconclusao" class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-clock-check-outline</v-icon>Data retorno
							</div>
							<div v-if="dados.usuarioconclusao" class="py-2 text-caption text-truncate text-md-h6">
								<v-icon size="20" class="mr-1">mdi-account</v-icon>Atendente
							</div>
						</v-col>
						<v-col class="text-right">
							<div class="py-2 text-caption text-truncate text-md-h6 d-block d-md-none">
								<v-btn
									v-if="usuario.idgrupo == 1"
									height="24"
									small
									rounded
									elevation="0"
									color="primary"
									class="mr-2"
									@click="dialogAtualizar = true"
								>
									<v-icon size="18">mdi-pencil</v-icon>
								</v-btn>
								<v-chip
									:color="dados.status == 'Concluído' ? 'green darken-4' : 'yellow darken-4'"
									dark
									x-small
									class="font-weight-bold py-3 rounded-pill"
								>{{dados.status}}</v-chip>
							</div>
							<div class="py-2 text-caption text-truncate text-md-h6 d-none d-md-block">
								<v-btn
									v-if="usuario.idgrupo == 1"
									fab
									x-small
									elevation="0"
									color="primary"
									class="mr-2"
									@click="dialogAtualizar = true"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
								<v-chip
									:color="dados.status == 'Concluído' ? 'green darken-4' : 'yellow darken-4'"
									dark
									class="font-weight-bold"
								>{{dados.status}}</v-chip>
							</div>
							<div
								class="py-2 text-caption text-truncate text-md-h6"
							>{{dados.departamento | formataTextoCurto}}</div>
							<div
								class="py-2 text-caption text-truncate text-md-h6"
								v-if="dados.categoria"
							>{{dados.categoria.split(' ')[0]}}</div>
							<div
								class="py-2 text-caption text-truncate text-md-h6"
								v-if="dados.subcategoria"
							>{{dados.subcategoria | formataTextoCurto}}</div>
							<div
								class="py-2 text-caption text-truncate text-md-h6"
							>{{dados.usuariocadastro | formataTextoCurto}}</div>
							<div
								v-if="dados.idconexaoremota"
								class="py-2 text-caption text-truncate text-md-h6"
							>{{dados.idconexaoremota}}</div>
							<div class="py-2 text-caption text-truncate text-md-h6">{{dados.filial | formataTextoCurto}}</div>
							<div
								v-if="dados.dataabertura"
								class="py-2 text-caption text-truncate text-md-h6 d-block d-md-none"
							>{{dados.dataabertura.split(' ')[0]}}</div>
							<div
								class="py-2 text-caption text-truncate text-md-h6 d-none d-md-block"
							>{{dados.dataabertura}}</div>
							<div
								v-if="dados.dataconclusao"
								class="py-2 text-caption text-truncate text-md-h6 d-block d-md-none"
							>{{dados.dataconclusao.split(' ')[0]}}</div>
							<div
								v-if="dados.dataconclusao"
								class="py-2 text-caption text-truncate text-md-h6 d-none d-md-block"
							>{{dados.dataconclusao}}</div>
							<div
								v-if="dados.usuarioconclusao"
								class="py-2 text-caption text-truncate text-md-h6"
							>{{dados.usuarioconclusao}}</div>
						</v-col>

						<v-col cols="12" class="mb-n8">
							<div class="text-h6 font-weight-bold">Descrição</div>
							<v-textarea outlined rows="3" readonly :value="dados.descricao" />
						</v-col>
						<v-col cols="12" class="mb-n8">
							<div class="text-h6 font-weight-bold">Solução</div>
							<v-textarea
								v-model="dados.solucao"
								outlined
								rows="3"
								:readonly="dados.status == 'Concluído'"
							></v-textarea>
						</v-col>
						<v-col v-if="usuario.idusuario != dados.idusuariocadastro" cols="12" class="mb-n8">
							<v-select
								:items="status.lista"
								item-text="status"
								item-value="idstatus"
								dense
								label="Novo status"
								outlined
								v-model="idstatus"
							></v-select>
						</v-col>
						<v-col>
							<v-btn
								v-if="usuario.idusuario == dados.idusuariocadastro && dados.status == 'Concluído'"
								class="px-3"
								color="primary"
								elevation="0"
								block
								@click="registro = '', dialogReabertura = true"
							>Reabrir Chamado</v-btn>
							<v-btn
								v-else-if="usuario.idusuario != dados.idusuariocadastro"
								class="px-3"
								color="primary"
								elevation="0"
								block
								@click="atualizarChamado()"
							>Atualizar</v-btn>
							<v-btn
								class="px-3 text-decoration-underline"
								elevation="0"
								color="primary"
								block
								text
								to="/chamado"
								exact
							>Voltar</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>


<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "ChamadoGet",
	components: { CardDialog },
	props: ["idchamado"],
	data: () => ({
		dialogAtualizar: false,
		dialogArquivoNovo: false,
		dialog: { status: false, title: "", msg: "", icon: "", iconColor: "" },
		dialogReabertura: false,
		snackbar: { status: false, timeout: 1500 },
		chamado: true,
		carregando: false,
		status: [],
		dados: { solucao: "" },
		dadosNovo: { iddepartamento: 0, idcategoria: 0, idsubcategoria: 0 },
		atualizar: false,
		registros: {},
		registro: "",
		idstatus: 4,
		contador: 0,
		arquivos: [],
		departamentos: { lista: [] },
		categorias: { lista: [] },
		subCategorias: { lista: [] },
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	methods: {
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/get`, {
					idchamado: parseInt(this.idchamado),
				})
				.then((res) => {
					this.carregando = false;
					this.dados = res.data;
					if (!this.dados.solucao) {
						this.dados.solucao = "";
					}
					this.dados.solucao = this.dados.solucao.replaceAll(
						"/ln",
						"\n"
					);
					this.dados.descricao = this.dados.descricao.replaceAll(
						"/ln",
						"\n"
					);
					this.listarDepartamentos();
					this.listarCategorias();
					if (this.dados.idsubcategoria) {
						this.listarSubCategorias();
					}
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listarStatus() {
			return axios
				.post(`${this.backendUrl}chamado/status`, {})
				.then((res) => {
					this.status = res.data;
				});
		},
		listarRegistros() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/registro/listar`, {
					idchamado: parseInt(this.idchamado),
				})
				.then((res) => {
					this.registros = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		atualizarChamado() {
			this.dialog = {
				status: false,
				title: "",
				msg: "",
				icon: "",
				iconColor: "",
			};
			if (this.idstatus == 4) {
				if (this.dados.solucao == "") {
					this.dialog.status = true;
					this.dialog.title = "Erro";
					this.dialog.msg =
						"A solução do chamado não foi definida, preencha o campo de solução antes de concluir!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					return this.dialog;
				}
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/atualizar`, {
					idchamado: parseInt(this.idchamado),
					idstatus: parseInt(this.idstatus),
					solucao: this.dados.solucao.replaceAll("\n", "/ln"),
				})
				.then((res) => {
					if (res.data) {
						this.dialog.status = true;
						this.dialog.title = "Chamado atualizado";
						this.dialog.msg = "Chamado atualizado com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.get();
						let status = this.status.lista
							.filter((v) => v.idstatus == this.idstatus)
							.map((v) => v.status);
						this.criarNotificacao({
							notificacao: `O chamado ${this.idchamado} foi atualizado pelo usuário ${this.usuario.usuario}, seu novo status é "${status}".`,
							caminho: "get/",
							idusuario: parseInt(this.dados.idusuariocadastro),
						});
					} else {
						this.dialog.status = true;
						this.dialog.title = "Erro";
						this.dialog.msg = "Não foi possível alterar o chamado!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
					}
					this.carregando = false;
					return this.dialog;
				})
				.catch(() => {
					this.dialog.status = true;
					this.dialog.title = "Erro";
					this.dialog.msg = "Não foi possível alterar o chamado!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.carregando = false;
					return this.dialog;
				});
		},
		arquivoDownload(idarquivo) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/arquivo/download`, {
					idarquivo,
				})
				.then((res) => {
					this.downloadBase64(res.data);
					this.carregando = false;
				});
		},
		arquivoNovo() {
			return axios
				.post(`${this.backendUrl}chamado/arquivo/novo`, {
					idchamado: parseInt(this.idchamado),
					arquivo: this.arquivos || null,
				})
				.then((res) => {
					if (res.data) {
						this.dialogArquivoNovo = false;
						this.dialog.title = "Arquivo adicionado";
						this.dialog.msg = "Arquivo adicionado com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.dialog.status = true;
						this.get();
						if (!this.dados.idusuarioconclusao) {
							return;
						}
						let idusuario;
						let usuario;
						if (
							this.dados.idusuariocadastro ==
							this.usuario.idusuario
						) {
							idusuario = this.dados.idusuarioconclusao;
							usuario = this.usuario.usuario;
						} else {
							idusuario = this.usuario.idusuario;
							usuario = this.dados.usuarioconclusao;
						}
						this.criarNotificacao({
							notificacao: `O usuário ${usuario} adicionou um novo arquivo para o chamado ${this.dados.idchamado}."`,
							caminho: "get/",
							idusuario: parseInt(idusuario),
						});
					} else {
						this.dialogArquivoNovo = false;
						this.dialog.title = "Erro";
						this.dialog.msg =
							"Não foi possível adicionar o arquivo ao chamado!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
						this.dialog.status = true;
					}
				})
				.catch(() => {
					this.dialogArquivoNovo = false;
					this.dialog.title = "Erro";
					this.dialog.msg =
						"Não foi possível adicionar o arquivo ao chamado!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.dialog.status = true;
				});
		},
		adicionarArquivo(arquivos) {
			this.carregando = true;
			this.arquivos = [];
			let a = [];
			for (let i = 0; i < arquivos.length; i++) {
				let reader = new FileReader();
				reader.readAsDataURL(arquivos[i]);
				reader.onload = function () {
					a[i] = {
						arquivo: reader.result.split("base64,")[1],
						arquivoname: arquivos[i].name,
					};
				};
			}
			this.arquivos = a;
			this.carregando = false;
		},
		reabrirChamado() {
			this.adicionarRegistro();
			if (this.registro == "") {
				this.dialog.status = true;
				this.dialog.title = "Erro";
				this.dialog.msg =
					"É obrigatório preencher o campo com o motivo da reabertura do chamado.";
				this.dialog.icon = "mdi-alert-circle-outline";
				this.dialog.iconColor = "red";
				return;
			}
			this.dialog = {
				status: false,
				title: "",
				msg: "",
				icon: "",
				iconColor: "",
			};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/reabrir`, {
					idchamado: parseInt(this.idchamado),
				})
				.then((res) => {
					if (res.data) {
						this.dialog.status = true;
						this.dialog.title = "Chamado reaberto";
						this.dialog.msg = "Chamado reaberto com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.get();
						this.criarNotificacao({
							notificacao: `O chamado ${this.idchamado} foi reaberto pelo usuário ${this.dados.usuariocadastro} da filial ${this.dados.filial}.`,
							caminho: "get/",
							idusuario: parseInt(this.dados.idusuarioconclusao),
						});
						this.dialogReabertura = false;
					} else {
						this.dialog.status = true;
						this.dialog.title = "Erro";
						this.dialog.msg = "Não foi possível reabrir o chamado!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
					}
					this.carregando = false;
					return this.dialog;
				})
				.catch(() => {
					this.dialog.status = true;
					this.dialog.title = "Erro";
					this.dialog.msg = "Não foi possível reabrir o chamado!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.carregando = false;
					return this.dialog;
				});
		},
		atualizarCompleto() {
			this.dialog = {
				status: false,
				title: "",
				msg: "",
				icon: "",
				iconColor: "",
			};
			if (
				this.dadosNovo.idcategoria == "" ||
				this.dadosNovo.iddepartamento == "" ||
				(this.subCategorias.lista.length > 0 &&
					this.dadosNovo.idsubcategoria == "")
			) {
				this.dialog.status = true;
				this.dialog.title = "Erro";
				this.dialog.msg =
					"Um ou mais campos não foram preenchidos, preencha todos campos antes de prosseguir!";
				this.dialog.icon = "mdi-alert-circle-outline";
				this.dialog.iconColor = "red";
				return this.dialog;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/atualizar/completo`, {
					idchamado: parseInt(this.idchamado),
					iddepartamento: parseInt(this.dadosNovo.iddepartamento),
					idcategoria: parseInt(this.dadosNovo.idcategoria),
					idsubcategoria: parseInt(this.dadosNovo.idsubcategoria),
				})
				.then((res) => {
					if (res.data) {
						this.dialog.status = true;
						this.dialog.title = "Chamado atualizado";
						this.dialog.msg = "Chamado atualizado com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.get();
						let departamento = this.departamentos.lista.filter(
							(v) =>
								v.iddepartamento ==
								this.dadosNovo.iddepartamento
						)[0].departamento;
						this.criarNotificacao({
							notificacao: `O chamado ${this.idchamado} foi redirecionado pelo usuário ${this.usuario.usuario} da filial ${this.usuario.filial} para o departamento de ${departamento}.`,
							caminho: "get/",
							idusuario: parseInt(this.dados.idusuariocadastro),
						});
						this.dialogAtualizar = false;
					} else {
						this.dialog.status = true;
						this.dialog.title = "Erro";
						this.dialog.msg =
							"Não foi possível atualizar o chamado!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
					}
					this.carregando = false;
					return this.dialog;
				});
		},
		adicionarRegistro() {
			this.dialog = {
				status: false,
				title: "",
				msg: "",
				icon: "",
				iconColor: "",
			};
			if (this.registro == "") {
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/registro/criar`, {
					idchamado: parseInt(this.idchamado),
					registro: this.registro,
				})
				.then((res) => {
					if (res.data) {
						this.snackbar.text = "Registro adicionado com sucesso";
						this.snackbar.status = true;
						let idusuario;
						let usuario;
						if (
							this.dados.idusuariocadastro ==
							this.usuario.idusuario
						) {
							idusuario = this.dados.idusuarioconclusao;
							usuario = this.dados.usuariocadastro;
						} else {
							idusuario = this.dados.idusuariocadastro;
							usuario = this.dados.usuarioconclusao;
						}
						this.criarNotificacao({
							notificacao: `O usuário ${usuario} enviou um novo registro para o chamado ${this.dados.idchamado}. </br>Registro: "${this.registro}"`,
							caminho: "get/",
							idusuario: parseInt(idusuario),
						});
						this.registro = "";
					} else {
						this.dialog.status = true;
						this.dialog.title = "Erro";
						this.dialog.msg =
							"Não foi possível adicionar o registro ao chamado!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
					}
					this.carregando = false;
					return this.dialog;
				})
				.catch(() => {
					this.dialog.status = true;
					this.dialog.title = "Erro";
					this.dialog.msg =
						"Não foi possível adicionar o registro ao chamado!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.carregando = false;
					return this.dialog;
				});
		},
		criarNotificacao(notificacao) {
			return axios.post(`${this.backendUrl}chamado/notificacao/criar`, {
				notificacao: notificacao.notificacao,
				caminho: notificacao.caminho,
				idchamado: parseInt(this.idchamado),
				idusuario: notificacao.idusuario,
			});
		},
		listarDepartamentos() {
			this.departamentos.lista = [];
			return axios
				.post(`${this.backendUrl}departamento/listar`, {
					limit: 9999,
					offset: 0,
					busca: null,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.departamentos = res.data;
					}
				});
		},
		listarCategorias() {
			this.categorias.lista = [];
			if (!this.dadosNovo.iddepartamento) {
				this.dadosNovo.iddepartamento = this.dados.iddepartamento;
			}
			return axios
				.post(`${this.backendUrl}chamado/categoria`, {
					iddepartamento: this.dadosNovo.iddepartamento,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.categorias = res.data;
					}
				});
		},
		listarSubCategorias() {
			this.subCategorias.lista = [];
			if (!this.dadosNovo.idcategoria) {
				this.dadosNovo.idcategoria = this.dados.idcategoria;
			}
			return axios
				.post(`${this.backendUrl}chamado/subcategoria`, {
					idcategoria: this.dadosNovo.idcategoria,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.subCategorias = res.data;
						if (this.dados.idsubcategoria) {
							this.dadosNovo.idsubcategoria =
								this.dados.idsubcategoria;
						}
					}
				});
		},
		async init() {
			await this.get();
			await this.listarStatus();
			await this.listarRegistros();
		},
	},
	watch: {
		idchamado: function () {
			this.init();
		},
	},
	created() {
		this.init();
	},
	mounted() {
		var channel = this.$pusher.subscribe("my-channel");
		channel.bind(`chamado-${this.idchamado}`, (data) => {
			this.registros.lista.push(data);
		});
	},
	beforeDestroy() {
		this.$pusher.unsubscribe("my-channel");
	},
};
</script>

<style scoped>
.container-scroll {
	min-height: 140px;
	max-height: 260px;
	overflow: auto;
	display: flex;
	flex-direction: column-reverse;
}
</style>